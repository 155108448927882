import './TermsList.scss';

import React from 'react';
import { Link } from 'gatsby';
import { sortBy } from 'lodash';

import ThumbnailFrameworkGraph from 'components/tree-diagrams/ThumbnailFrameworkGraph';
import { Framework, Term } from 'types';

interface Props {
  frameworks: Framework[];
  terms: Term[];
}

/**
 * List all the given terms in a table.
 */

const TermsList: React.FC<Props> = (props) => {
  const { terms } = props;
  const frameworks = sortBy(props.frameworks, (f) => f.name);

  const termsForFrameworkWithID = (frameworkID: number): Term[] => {
    return terms.filter((term) => term.frameworkID === frameworkID);
  };

  return (
    <div className="TermsList">
      {frameworks.map((framework) => {
        const terms = termsForFrameworkWithID(framework.identifier);
        const listedTerms = sortBy(
          terms.filter((t) => t.includeInThesaurus),
          (t) => t.name
        );

        return (
          <div className="TermsList-framework" key={framework.identifier}>
            <div className="TermsList-framework-label">
              <Link
                to={`/frameworks/${framework.identifier}/`}
                className="TermsList-framework-label-details"
              >
                {terms.length > 0 && (
                  <div className="TermsList-thumbnailGraph">
                    <ThumbnailFrameworkGraph
                      framework={framework}
                      terms={terms}
                      width={34}
                      height={44}
                    />
                  </div>
                )}

                <h2 className="TermsList-framework-label-details-title">
                  {framework.name}
                </h2>
              </Link>

              {listedTerms.length > 0 && (
                <Link
                  to={`/frameworks/${framework.identifier}/terms`}
                  className="TermsList-framework-label-details-link no-print"
                >
                  View All Term Definitions
                </Link>
              )}
            </div>

            <div className="TermsList-framework-terms">
              {listedTerms.map((term) => (
                <div className="TermsList-framework-term" key={term.identifier}>
                  <Link to={`/terms/${term.identifier}/`}>{term.name}</Link>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TermsList;
