import './TermsSearchResults.scss';

import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import * as JSSearch from 'js-search';
import { sortBy } from 'lodash';

import { Term } from 'types';

interface Props {
  terms: Term[];
  searchQuery: string;
}

/**
 * Lists terms matching a given search query in a table.
 */
const TermsSearchResults: React.FC<Props> = (props) => {
  const { terms, searchQuery } = props;

  const searchIndex = useMemo(() => {
    const searchIndex = new JSSearch.Search('identifier');
    searchIndex.addIndex('name');
    searchIndex.addDocuments(terms);
    return searchIndex;
  }, [terms]);

  if (props.searchQuery == null) {
    return null;
  }

  const results = sortBy(
    searchIndex.search(searchQuery),
    (t: Term) => t.name.toLowerCase(),
    (t: Term) => t.frameworkName.toLowerCase()
  );

  return (
    <div className="TermsSearchResults">
      {results.map((term: any) => (
        <div className="TermsSearchResults-term" key={term.identifier}>
          <Link
            to={`/terms/${term.identifier}/`}
            className="TermsSearchResults-term-name"
          >
            {term.name}
          </Link>
          <Link
            to={`/frameworks/${term.frameworkID}/`}
            className="TermsSearchResults-term-framework"
          >
            {term.frameworkName}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default TermsSearchResults;
