import './terms.scss';

import React from 'react';
import { graphql } from 'gatsby';

import searchIcon from 'assets/images/search-icon.png';

import Layout from 'components/layout/Layout';
import TermsList from 'components/terms/TermsList';
import TermsSearchResults from 'components/terms/TermsSearchResults';

import { Framework, Term } from 'types';

interface Props {
  data: {
    allFrameworks: {
      frameworks: Framework[];
    };
    allTerms: {
      terms: Term[];
    };
  };
}

interface State {
  searchQuery: string;
}

export default class TermsPage extends React.Component<Props, State> {
  state: State = {
    searchQuery: ''
  };

  searchChanged = (event: React.SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      searchQuery: event.currentTarget.value
    });
  };

  clearSearch = () => {
    this.setState({
      searchQuery: ''
    });
  };

  render() {
    const { frameworks } = this.props.data.allFrameworks;
    const { terms } = this.props.data.allTerms;

    const { searchQuery } = this.state;
    const isSearching = searchQuery !== '';

    return (
      <Layout>
        <div className="TermsPage-header">
          {isSearching ? (
            <div>
              <h1>Search results for “{searchQuery}”</h1>
              <button
                className="TermsPage-header-search-clear-button"
                onClick={this.clearSearch}
              >
                Clear Search Results
              </button>
            </div>
          ) : (
            <div>
              <h1>Search for Skills</h1>
              <p className="emphasis">
                There is a lack of agreement about how to define and label SEL
                and non-academic skills. Conflicting terminology is often
                confusing and can lead to misalignment between skills,
                strategies, measures, and desired outcomes.
              </p>
              <p className="emphasis">
                Explore our thesaurus of 200+ social, emotional, and other
                non-academic terms to identify overlapping or related terms by
                how they are defined rather than named or labeled.
              </p>
            </div>
          )}

          <input
            aria-label="Search Terms"
            className="TermsPage-header-searchInput no-print"
            placeholder="Search terms"
            type="text"
            value={searchQuery}
            onChange={this.searchChanged}
            style={{ backgroundImage: `url(${searchIcon})` }}
          />
        </div>

        <div aria-live="polite">
          <TermsSearchResults terms={terms} searchQuery={searchQuery} />

          {!isSearching && <TermsList frameworks={frameworks} terms={terms} />}
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query AllTermsQuery {
    allFrameworks: allFrameworksJson {
      frameworks: nodes {
        identifier
        name
        color
      }
    }
    allTerms: allTermsJson {
      terms: nodes {
        identifier
        name
        frameworkName
        frameworkID
        includeInThesaurus
        parentID
      }
    }
  }
`;
